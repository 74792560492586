import Vue from 'vue'
import store from "../store/index";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueRouter from 'vue-router'
//主页
import Home from '../views/Home/Home.vue'
//登录
import Login from '../views/Login/Login.vue'
import Report from '../views/Home/404.vue'
NProgress.configure({
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
})
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    redirect: '/index',
    component: Home,
    children: [
      {
        path: '/index',
        component: () => import("@/views/Home/Index/index.vue"),
        meta: { name: '人员价格管理' }
      },
      {
        path: '/protocolList',
        component: () => import("@/views/Home/protocolList/protocolList.vue"),
        meta: { name: '协议条款列表' }
      },
      {
        path: '/agreementListAdd',
        component: () => import("@/views/Home/protocolList/agreementListAdd.vue"),
        meta: { name: '协议条款编辑' },
        name:'agreementListAdd'
      },
      {
        path: '/areaList',
        component: () => import("@/views/Home/areaList/areaList.vue"),
        meta: { name: '区域列表' },
        name:'areaList'
      },
      {
        path: '/siteList',
        component: () => import("@/views/Home/siteList/siteList.vue"),
        meta: { name: '站点列表' },
        name:'siteList'
      },
      {
        path: '/serverList',
        component: () => import("@/views/Home/serveList/serveList.vue"),
        meta: { name: '服务模型列表' },
        name:'serverList'
      },
      {
        path: '/siteServer',
        component: () => import("@/views/Home/siteServer/siteServer.vue"),
        meta: { name: '服务模型列表' },
        name:'siteServer'
      },
      {
        path: '/servePriceList',
        component: () => import("@/views/Home/serveList/servePriceList.vue"),
        meta: { name: '服务规格定价' },
        name:'servePriceList'
      },
      {
        path: '/routeList',
        component: () => import("@/views/Home/routeList/routeList.vue"),
        meta: { name: '路线列表' },
        name:'routeList'
      },
      {
        path: '/routeDetail',
        component: () => import("@/views/Home/routeList/routeDetail.vue"),
        meta: { name: '路线详情' },
        name:'routeDetail'
      },
      {
        path: '/classList',
        component: () => import("@/views/Home/classList/classList.vue"),
        meta: { name: '分类列表' },
        name:'classList'
      },
      {
        path: '/orderList',
        component: () => import("@/views/Home/orderList/orderList.vue"),
        meta: { name: '订单列表' },
        name:'orderList'
      },
    ]
  },
  {
    path: '/orderDetail',
    name:'orderDetail',
    component: Home,
    children:[   {
      path: '/orderDetail',
      name:'orderDetail',
      component: () => import("@/views/Home/orderList/orderDetail.vue"),
      meta: { name: '订单详情' }
    },]
  },
  {
    //登录
    path: '/login',
    name: 'login',
    component: Login
  },
  // 404页面
  {
    path: "*",
    component: Report
  }
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // store.commit("getroute",{ path:to.path,query:to.query});
  // console.log(store.state.history);
  NProgress.start();
  let token = sessionStorage.getItem('token')
  if (to.path === '/login') {
    // let info = []
    // router.addRoutes(info)
    return next()
  }
  if (!token) {
    next('/login')
  }
  next()
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
export default router
