import {post} from '@/utils/request'

export function getNavList() {
  return post('admin/department/list')
}
export function getOptionTask() {
  return post('task/task/choice')
}
export function logList() {
  return post('admin/log/list')
}