<!--  -->
<template>
  <div class="cw" v-dragscroll>
    <img src="@/assets/404.png" alt="" width="400" />
    <el-button type="warning" @click="goHome">返回上一页</el-button>
  </div>
</template>

<script>
import { number } from "echarts";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      fabuText: "",
      friendList: [
        { nickName: "测试", userId: 1 },
        { nickName: "测试2", userId: 2 },
        { nickName: "测试3", userId: 3 },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    goHome() {
      this.$router.go(-1);
    },
    
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.cw {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cw {
  display: flex;
}
.img {
  flex: 1;
  width: 700px;
  height: 500px;
}
.backhome {
  width: 500px;
  height: 50px;
  position: absolute;
  bottom: 100px;
}
.list {
  width: 100px;
  border: 1px solid red;
  /* margin: auto; */
  margin: 10px auto;
}
.goods {
  margin-left: 20px;
}
.list-all {
  width: 105px;
  border: 1px solid black;
  margin-left: 20px;
  height: 500px;
  padding: 20px;
}
</style>